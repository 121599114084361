import {
  ActiveFeatures,
  CatalogServiceDto,
  ServiceType,
} from '@wix/bookings-uou-types';
import { ReferralInfo } from '../../utils/bi/consts';
import {
  BookingValidations,
  getNotifyPricingPlanRequest,
} from '../../utils/validation/booking-validations';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BookingsApi } from '../../api/BookingsApi';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { openServicesPreferencesModalAction } from '../openServicesPreferencesModal/openServicesPreferencesModal';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { bookingsCantBookGroupsMessage } from '@wix/bi-logger-wixboost-ugc/v2';
import { OpenPremiumDialogAction } from '../openPremiumDialog/openPremiumDialog';
import { WidgetConfig } from '../../../legacy/types';
import { ServiceIntent } from '../../consts';

export type OnBookFlowStartAttemptAction = ({
  service,
  referralInfo,
}: {
  service: CatalogServiceDto;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnBookFlowStartAttemptAction = ({
  wixSdkAdapter,
  flowAPI,
  bookingsApi,
  config,
  openServicesPreferencesModal,
  navigateToNextPage,
  openPremiumDialog,
}: {
  wixSdkAdapter: WixOOISDKAdapter;
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsApi;
  config: WidgetConfig;
  openServicesPreferencesModal: openServicesPreferencesModalAction;
  openPremiumDialog: OpenPremiumDialogAction;
  navigateToNextPage: NavigateToNextPageAction;
}): OnBookFlowStartAttemptAction => {
  return async ({
    service,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    referralInfo: ReferralInfo;
  }) => {
    const bookingsValidations = new BookingValidations(bookingsApi, flowAPI);
    try {
      // TODO: refactor bookingsValidation and replace it here
      const { canBook, reason } = await bookingsValidations.shouldNavigate(
        service,
        JSON.parse(config.activeFeatures) as ActiveFeatures,
        ServiceIntent.BOOK_OFFERING,
      );

      if (canBook) {
        if (
          flowAPI.experiments.enabled(
            'specs.bookings.multiServiceAppointment',
          ) &&
          !!config.businessInfo?.isMultiServicesAppointmentsEnable &&
          !service.dummy &&
          service.type === ServiceType.INDIVIDUAL
        ) {
          await openServicesPreferencesModal({ service });
        } else {
          await navigateToNextPage({
            service,
            referralInfo,
            target: NavigationTarget.CALENDAR_PAGE,
          });
        }
      } else {
        if (reason) {
          try {
            if (reason.premiumError) {
              await bookingsApi.notifyOwnerNonPremiumEnrollmentAttempt({
                service,
              });
            } else if (reason.pricingPlanError) {
              await bookingsApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
                getNotifyPricingPlanRequest(service, reason),
              );
            }
          } catch (error) {
            console.error(error);
          }
        }

        flowAPI.bi?.report(
          bookingsCantBookGroupsMessage({
            referralInfo: ReferralInfo.WIDGET,
            failReason: reason?.siteNotEcomError ? 'non ecom site' : undefined,
          }),
        );

        await openPremiumDialog();
      }
    } catch (error) {
      console.error(error);
    }
  };
};
