import { CatalogServiceDto } from '@wix/bookings-uou-types';
import {
  ActionNames,
  BiServiceType,
  ReferralInfo,
  serviceListReferralInfo,
} from '../../utils/bi/consts';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigationTarget } from './consts';
import { WidgetConfig } from '../../../legacy/types';
import {
  getTrackingInfoForBookButtonClick,
  getTrackingInfoForNavigateToServicePageClick,
} from '@wix/bookings-analytics-adapter';
import { bookingsBookItClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';
import { getLocationId } from '../../utils/getLocationId/getLocationId';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { Basket } from '@wix/bookings-services-preferences-modal/types';
import {
  navigateToBookingsCalendarPage,
  navigateToBookingsServicePage,
} from '@wix/bookings-catalog-calendar-viewer-navigation';
import { trackAnalytics } from '@wix/bookings-catalog-calendar-viewer-utils';

export type NavigateToNextPageAction = ({
  service,
  target,
  referralInfo,
  basket,
}: {
  service?: CatalogServiceDto;
  target: NavigationTarget;
  referralInfo?: ReferralInfo;
  basket?: Basket;
}) => Promise<void>;

export const createNavigateToNextPageAction = ({
  config,
  flowAPI,
  widgetViewModel,
}: {
  config: WidgetConfig;
  flowAPI: ControllerFlowAPI;
  widgetViewModel: WidgetViewModel;
}): NavigateToNextPageAction => {
  const {
    bi,
    controllerConfig: { wixCodeApi, platformAPIs },
  } = flowAPI;

  return async ({ basket, service, target, referralInfo }) => {
    const queryParam = getResourceQueryParam(wixCodeApi);
    const locationId = getLocationId(flowAPI, widgetViewModel);
    const optionalParams = {
      referral: serviceListReferralInfo,
      ...(queryParam.staff ? { staff: queryParam.staff } : {}),
      ...(queryParam.resource ? { resource: queryParam.resource } : {}),
      ...(locationId ? { location: locationId } : {}),
    };

    if (!service) {
      if (!basket) {
        console.error('both service and basket are missing');
        return;
      }
      return navigateToBookingsCalendarPage(
        flowAPI.controllerConfig.wixCodeApi,
        flowAPI.controllerConfig.platformAPIs,
        {
          serviceSlugOrBasket: basket,
          navigationContext: optionalParams,
        },
      );
    }
    const data = {
      service,
      businessName: config.businessInfo.name!,
    };
    const trackingInfo =
      target === NavigationTarget.CALENDAR_PAGE
        ? getTrackingInfoForBookButtonClick(data)
        : getTrackingInfoForNavigateToServicePageClick(data);

    trackAnalytics({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      eventId: trackingInfo.eventId as any,
      payload: trackingInfo.payload,
    });

    bi?.report(
      bookingsBookItClick({
        service_id: service.id,
        type: BiServiceType[service.type],
        isPendingApproval: service.schedulePolicy.isPendingApprovalFlow,
        referralInfo,
        actionName: ActionNames[target],
      }),
    );
    try {
      switch (target) {
        case NavigationTarget.CALENDAR_PAGE:
          return navigateToBookingsCalendarPage(wixCodeApi, platformAPIs, {
            serviceSlugOrBasket: service.urlName,
            navigationContext: optionalParams,
          });

        case NavigationTarget.SERVICE_PAGE:
          return navigateToBookingsServicePage(wixCodeApi, {
            serviceSlug: service.dummy ? '' : service.urlName,
            ...optionalParams,
          });
      }
    } catch (e) {
      console.error(e);
    }
  };
};
